/**
 * Text Field Component
 * This component accepts one of multiple types {text, password, email}
 */
import React from "react"
import classnames from "classnames"
import ClipLoader from "react-spinners/ClipLoader"

const spinnerStyles = {
  position: "absolute",
  top: 20,
  right: 20,
}

function TextField({ loading, type, error, noSpacing, ...rest }) {
  return (
    <div className="relative">
      <input
        type={type ? type : "text"}
        className={classnames(
          "w-full px-4 py-3 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none",
          {
            "border-red-600": error,
            "mt-0": noSpacing,
            "mt-2": !noSpacing,
          }
        )}
        {...rest}
      />

      {loading && (
        <div style={{ ...spinnerStyles }}>
          <ClipLoader size={24} color="#3c366b" loading={loading} />
        </div>
      )}
    </div>
  )
}

export default TextField
