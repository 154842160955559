/**
 * Change Password Handler
 * Uses the auth0 sdk to change the current user's password
 */

async function changePassword(email) {
  const url = `https://${process.env.GATSBY_AUTH0_DOMAIN}/dbconnections/change_password`

  try {
    const res = await fetch(url, {
      headers: { "content-type": "application/json" },
      method: "post",
      body: JSON.stringify({
        client_id: process.env.GATSBY_AUTH0_CLIENTID,
        email,
        connection: "Username-Password-Authentication",
      }),
    })

    // Return the 'ok' status
    return res.ok
  } catch (err) {
    alert(
      "We are sorry. There was a problem submitting this request. Please try again"
    )
    console.log("There was an err", err)
  }
}

export default changePassword
