import React from "react"
import TextField from "./TextField"
import PropTypes from "prop-types"

export default function LabelledInput({
  defaultValue,
  label,
  type,
  name,
  id,
  value,
  onChange,
  error,
}) {
  const textFieldProps = {
    defaultValue,
    type,
    name,
    id,
    value,
    onChange,
    error,
  }

  return (
    <div className="my-3">
      <label className="block text-gray-700">
        {label}{" "}
        {error && (
          <span className="text-red-600 font-bold inline-block mx-auto float-right">
            {error}
          </span>
        )}
      </label>
      <TextField {...textFieldProps} />
    </div>
  )
}

LabelledInput.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
}

LabelledInput.defaultProps = {
  defaultValue: "",
  type: "text",
  value: "",
}
