import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ClipLoader from "react-spinners/ClipLoader"

export default function PositiveButton(props) {
  return (
    <button
      onClick={props.onClick}
      type={props.type}
      className={classNames(
        `${props.className} bg-green-600 hover:bg-green-800 focus:bg-green-600 block px-8 py-1 rounded text-white font-semibold`,
        {
          "rounded-lg px-4 py-3": props.size === "medium",
          "px-6": props.size === "large",
          "opacity-50 cursor-not-allowed": props.isLoading || props.disabled,
        }
      )}
    >
      {!props.isLoading ? (
        props.children
      ) : (
        <ClipLoader size={32} color="#ffffff" loading={props.isLoading} />
      )}
    </button>
  )
}

PositiveButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
}

PositiveButton.defaultProps = {
  isLoading: false,
  disabled: false,
  size: "medium",
  type: "button",
  onClick: null,
}
