import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ClipLoader from "react-spinners/ClipLoader"
import { Link } from "gatsby"

export default function Button(props) {
  if (props.href) {
    return (
      <Link
        to={props.href}
        className="bg-indigo-900 hover:bg-indigo-800 px-8 py-2 rounded focus:bg-blue-400 text-white font-semibold"
      >
        {props.children}
      </Link>
    )
  }

  return (
    <button
      onClick={props.onClick}
      type={props.type}
      className={classNames(
        `${props.className} hover:bg-indigo-800 px-8 py-2 rounded focus:bg-blue-400 text-white font-semibold flex items-center justify-center`,
        {
          "text-indigo-900 border-2 border-indigo-900 hover:text-white":
            props.isInverted,
          "bg-white": props.isInverted,
          "bg-indigo-900": !props.isInverted,
          "rounded-lg px-4 py-3": props.size === "medium",
          "px-6": props.size === "large",
          "opacity-50 cursor-not-allowed": props.isLoading || props.disabled,
        }
      )}
    >
      {!props.isLoading ? (
        props.children
      ) : (
        <ClipLoader color="#ffffff" size={24} loading={props.isLoading} />
      )}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  isInverted: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
}

Button.defaultProps = {
  isInverted: false,
  isLoading: false,
  disabled: false,
  size: null,
  type: "button",
  onClick: null,
  href: null,
}
