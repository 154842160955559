import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SplashImage = () => {
  return (
    <StaticImage
      src="../images/splash_image.png"
      className="w-full h-full object-cover mb-0"
    />
  )
}

export default SplashImage
