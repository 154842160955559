import React, { useState } from "react"
import classnames from "classnames"
import * as Yup from "yup"
import { Link } from "gatsby"
import { useFormik } from "formik"

import { Button } from "../button"
import changePassword from "./form-handlers/change-password"
import { TextField } from "../inputs"

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Oops. The email address doesn't look quite right.")
    .required("This is a required field"),
})

function ResetForm({ submitCallback }) {
  const [loading, setLoading] = useState(false)
  const [okStatus, setOkStatus] = useState()

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async values => {
      setLoading(true)

      const status = await changePassword(values.email)
      setOkStatus(status)
      setLoading(false)

      if (submitCallback) submitCallback()
    },
  })

  return !okStatus ? (
    <form onSubmit={formik.handleSubmit}>
      {okStatus && (
        <p className="mb-8 font-bold">
          An email was sent to {formik.values.email}. Please check your inbox
          for further instructions.
        </p>
      )}
      <div>
        <label className="block text-gray-700" htmlFor="email">
          Email Address
        </label>
        {formik.errors.email && (
          <p className="text-red-600 font-bold mb-0">{formik.errors.email}</p>
        )}
        <TextField
          id="email"
          name="email"
          type="email"
          placeholder="Enter Email Address"
          className={classnames(
            "w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",
            {
              "border border-red-600": formik.errors.email,
            }
          )}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </div>

      <Button type="submit" className={"w-full mt-6"} isLoading={loading}>
        Send Email
      </Button>
    </form>
  ) : null
}

export default ResetForm
